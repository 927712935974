import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import VideoEmbed from "../../components/video-embed"
import { Link } from "gatsby"
import Sidebar from "../../components/sidebar"

export default class MyPassionToIntroduceHope extends React.Component {
  render() {
    return (
      <Layout title="My Passion to Introduce Hope" sectionName="Stories" sectionPath="/stories">
        <Seo title="My Passion to Introduce Hope"/>
        <div className="container">
          <div className="row">
            <section className="col-sm-9">
              <VideoEmbed vimeoId="231558908" title="My Passion to Introduce Hope" useCinema={true} author="Anna" location="Arizona" />

              <p>I've been happily married for twenty-two years. I never thought I would have three kids and a family that supports each other, that has a foundation in Jesus, and just loves each other.</p>

              <p>There was a time in my life where I felt the same despair that so many of my people do. That's why too many Native young people are dying so young: hopelessness. Young people like my brother, Brian, who hung himself. I never expected that; that my younger brother would take his own life. I wish I could have been there and talked with him. I wish I could have been there to help him; just to even listen. I think that's what most kids need; is someone there to listen, to help them when they need it. I remember my uncle saying to me on that day, "There's hope. There's hope in Jesus and this is just a chapter. You have more potential than you think. There is hope."</p>

              <p>If there is a Native kid struggling, one place I would send them to is this amazing, one-of-a-kind Native discipleship conference called "Warrior Leadership Summit." Attending this conference truly changed my life, it opened my eyes, and it made the relationship that I have with Jesus real and not just a church thing. When a young Native American kid comes to Warrior Leadership Summit for the first time, they feel loved.</p>

              <p className="story-callout">"There's hope. There's hope in Jesus and this is just a chapter. You have more potential than you think. There is hope."</p>

              <p>Like so many reservation families, mine lived below the poverty level. My parents did not have jobs; we sometimes did not have money to even have food on the table.</p>

              <p>Most Native young people I know could not go to Warrior Leadership Summit unless somebody sends them. Hope for Native America helps provide a future for so many that are hurting by making attendance at WLS possible. My family members, my nieces and nephews, have been to this life-changing conference and they are living for Jesus now! I think they would not be here if it were not for WLS.</p>

              <p>You can be Hope for Native America, just by choosing a way to get involved.</p>

              <p className="mt-5 text-center">
                <Link className="btn btn-secondary" to="/get-involved/equip-a-leader">Equip A Leader</Link>
              </p>

            </section>
            <aside className="col-sm-3">
              <h2><span>Here's how <strong>you</strong> can</span><br/><em>get involved!</em></h2>
              <Sidebar title="Equip a Leader" 
                      description={`Equip young Native Christian leaders to reach their communities for Jesus and to be a living example of hope to broken young people.`}
                      url="/get-involved/equip-a-leader"
                      thumbnail="/video-thumbnails/equip-a-leader.jpg" />
            </aside>
          </div>
        </div>
      </Layout>
    )
  }
}
